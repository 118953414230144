import api from '@/services/api/api';

export const useSummerArticles = () => {
  const summerArticles = useQuery(
    ['summerArticles'],
    async () => {
      const { data } = await api.articles.getSummerArticles();
      return data;
    },
    {
      staleTime: Infinity,
      select: ({ data }) => data,
    },
  );

  onServerPrefetch(async () => {
    await summerArticles.suspense();
  });

  return {
    summerArticles,
  };
};
